<template>
  <div class="wrap">
    <img class="logo" src="./assets/img/login_logo.png" alt="">
    <div class="cotent">
      <div class="left">
       <div class="center">
          <p class="title">海外人士畅享大陆影音</p>
          <div class="line"></div>
          <div class="btn">一键穿越  高速稳定</div>
        </div>
        <div class="download">
        <div
          v-for="i in downloadList"
          :key="i.text"
        >
          <img :src=i.img alt="">
          <a @click="download(i)">{{ i.text }}</a>
        </div>
      </div>
      </div>

    <div class="right">
      <img class="rImg"  src="./assets/img/multiple devices.png" alt="">
    </div>
    </div>
    <div class="service">
      <div class="kefu">
        <img src="./assets/img/customer service.png" alt="">
        <a href="https://url.cn/5c9hAmt?_type=wpa&qidian=true&_pid=c23bzq.uu8plr.l01tvyjp">人工客服</a>
      </div>
      <div class="copyright">Copyright&nbsp;&copy;&nbsp;2017-{{ new Date().getFullYear() }}</div>

    </div>



  </div>
</template>
<script>
let iOSImg = require("./assets/img/iOS.png");
let AndroidImg = require("./assets/img/Android.png");
let WindowsImg = require("./assets/img/Windows.png");
let MacImg = require("./assets/img/Mac.png");
let ChromeImg = require("./assets/img/Google.png");
let TVImg = require("./assets/img/Android TV.png");

let downloadIos = "https://apps.apple.com/cn/app/id1171836473";
let downloadAndroid ="/static/package/Android/Transocks_google_v3.2.2.apk";
let downloadMac ="/static/package/Mac/Transocks_google_v3.0.9.dmg";
let downloadWindows ="/static/package/Windows/Transocks_Win_v3.3.1_google_x64_0421.exe";
let downloadTV ="/static/package/TV/Transocks_tv4.1.0_official_website.apk";
let downloadChrome ="https://chrome.google.com/webstore/detail/%E7%A9%BF%E6%A2%ADtransocks%E5%8A%A0%E9%80%9F%E5%99%A8/ikobbjiomdcedikdmkfhlhoagpailcbh";
export default {
  components: {  },
  data() {
    return {
      downloadList:[
        { id:'ios', img: iOSImg, text: "iOS下载",link:downloadIos },
        { id:'android', img: AndroidImg, text: "Android下载",link:downloadAndroid },
        { id:'pc', img: WindowsImg, text: "Windows下载",link:downloadWindows },
        { id:'mac_website', img: MacImg, text: "Mac下载",link:downloadMac },
        { id:'chrome', img: ChromeImg, text: "Chrome/Edge",link:downloadChrome},
        { id:'tv', img: TVImg, text: "Android TV下载" ,link:downloadTV },
      ],
    };
  },
  created() {
    fetch('https://www.transocks.com/api/1/app/links')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.downloadList.map(i => {
          i.link = data.url[i.id]
        })
      })
      .catch(error => {
        console.error('Error:', error);
      })
  },
  methods:{
    download(i) {
      if (i.text === "Windows下载") {
        window["gtag"]("event", "conversion", {
          send_to: "AW-10964940577/PNQTCOrQjNMDEKHuvuwo",
        });
      } else if (i.text === "Mac下载") {
        window["gtag"]("event", "conversion", {
          send_to: "AW-10964940577/INaZCO3QjNMDEKHuvuwo",
        });
      }
      window.open(i.link)
    }
  },
}

</script>
<style scoped lang="scss" src="./index.scss" />